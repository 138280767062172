/* Miscellanoeous */
body,
html {
  overflow-x: hidden;
  padding-right: 0; // resets padding right added by Bootstrap modal
}

// hari

.userdetails {
  border: 1px solid #d6cece;
  padding: 30px 30px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.4);
}
.imgicon {
  width: 70px;
  height: 70px;
  aspect-ratio: 3/2;
  object-fit: contain;
}
.ptag {
  border: 1px solid #fff;
  background-color: #e4e4e4;
  border-radius: 20px;
  padding: 2px 6px;
  position: absolute;
  bottom: -15px;
  left: 0px;
}
.ystar {
  margin-top: -3px;
}
.userdetails .table td {
  border-top: 1px solid #2c2e3342;
  color: #000;
  font-weight: 500;
}
.userdetails .table tr td:nth-child(1) {
  text-align: left;
}
.pick {
  font-weight: 400;
}

body {
  background: $white-bg;
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto;
}

.page-body-wrapper {
  min-height: calc(100vh - #{$navbar-height});
  @include display-flex();
  @include flex-direction(row);
  padding-left: 0;
  padding-right: 0;
  width: calc(100% - #{$sidebar-width-lg});
  transition: width $action-transition-duration
      $action-transition-timing-function,
    margin $action-transition-duration $action-transition-timing-function;

  &.full-page-wrapper {
    min-height: 100vh;
    width: 100%;
    padding-top: 0;

    .main-panel {
      padding-top: 0;

      .content-wrapper {
        padding: 0;

        > div {
          height: 100%;
        }
      }
    }
  }

  @media (max-width: 991px) {
    width: 100%;
  }
}

.main-panel {
  transition: width $action-transition-duration
      $action-transition-timing-function,
    margin $action-transition-duration $action-transition-timing-function;
  width: 100%;
  min-height: calc(100vh - #{$navbar-height});
  padding-top: $navbar-height;
  @include display-flex();
  @include flex-direction(column);
  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
  &.main-panel-only {
    transition: none;
  }
}

.content-wrapper {
  background: $content-bg;
  padding: $content-padding;
  width: 100%;
  @include flex-grow(1);
}

.container-scroller {
  overflow: hidden;
  display: flex;
  position: relative;
}

pre {
  background: color(gray-lighter);
  padding: 15px;
  font-size: 14px;
}

code {
  padding: 5px;
  color: theme-color(danger);
  font-family: $type1;
  font-weight: $font-weight-light;
  font-size: $default-font-size;
  border-radius: 4px;
}

.page-header {
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .align-items-center;
  margin: 0 0 1.5rem 0;
  .breadcrumb {
    border: 0;
    margin-bottom: 0;
  }
}
.page-title {
  color: $body-color;
  font-size: 1.125rem;
  margin-bottom: 0;
  .page-title-icon {
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    text-align: center;
    box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35);
    i {
      font-size: 0.9375rem;
      line-height: 36px;
    }
  }
}

// Hari css

// dashboard

.content-wrapper {
  background-color: $white-bg;
}
.new_card {
  border: 1px solid $light-grey;
}
.card_new_title {
  padding: 20px 20px;
  background: $light-grey;
  color: $light-black;
  font-weight: 450;
  font-size: 16px;
}
.new_headings {
  color: $light-black;
  font-size: 19px;
  font-weight: 450;
}
.new_headings_small {
  color: $light-black;
  font-size: 16px;
  font-weight: 450;
}
.table_card_body {
  padding: 0px !important;
}
.table_card_body th:first-child,
.table_card_body td:first-child {
  padding-left: 50px !important;
}
.table_card_body tr th:last-child,
.table_card_body tr th:last-child {
  padding-right: 50px !important;
}
.approve_status {
  background: $green2-bg;
  width: fit-content;
  padding: 10px;
  color: $white-bg;
  border-radius: 3px;
}
.pending_status {
  background: $orange-bg;
  width: fit-content;
  padding: 10px 15px;
  color: $white-bg;
  border-radius: 3px;
}
.table_card_body .table tr th {
  border: none !important;
}
.table_card_body .table td {
  border: none !important;
}
.ride_card {
  background-color: $white-bg;
}
.ride_card thead tr {
  background: $light-grey;
}
.col_width {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.green {
  background-image: linear-gradient(to right, #2bf2bc, #15a09a);
}
.blue {
  background-image: linear-gradient(to right, #25a2e3, #1582b7);
}
.orange {
  background-image: linear-gradient(to right, #fd8048, #e17f54);
}
.violet {
  background-image: linear-gradient(to right, #4d58f9, #606ccd);
}
.darkBlue {
  background-image: linear-gradient(to right, #27a6e7, #1581b5);
}
.red {
  background: linear-gradient(to right, #f22b2b, #ac3742);
}
.white_img_bg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: $white-bg;
}

.white_img_bg img {
  width: 30px !important;
}

.data_wrapper {
  border-radius: 6px;
  transition: 0.4s;
  cursor: pointer;
}
.data_wrapper:hover {
  transform: scale(97%);
}
.data_wrapper .first_div:first-child {
  gap: 10px;
}
.data_wrapper .first_div .title {
  margin-bottom: 0px !important;
  color: $white;
  font-weight: 350;
  font-size: 12px;
}
.data_wrapper .first_div .value {
  margin-bottom: 0px !important;
  color: $white;
  font-weight: 400;
  font-size: 18px;
}

.data_wrapper .second_div .icon {
  cursor: pointer;
}

// rate card

.card_width {
  width: 100% !important;
}
.card_bg {
  background-color: $white-bg;
  border: none !important;
  box-shadow: 0px 0px 8px -3px grey;
}
.table_title {
  margin-bottom: 0px !important;
  color: $light-black;
  font-weight: 400;
  font-size: 15px;
}
.css-yk16xz-control {
  border-color: $green-bg !important;
}
.css-2b097c-container:focus-visible {
  border: none !important;
}
.rst_btn {
  background-color: $green-bg;
  border: none;
  cursor: pointer;
  padding: 7px 20px;
  border-radius: 3px;
  color: white;
  font-size: 15px;
  transition: 0.3s;
}
.rst_btn:hover {
  transform: scale(95%);
  border: none !important;
}
.css-yk16xz-control {
  // min-width: 150px;
}
.rst_btn.surcharge_btn {
  background-color: $green2-bg;
  white-space: nowrap;
  width: 100% !important;
}
.rst_btn.surcharge_btn:hover {
  color: $white-bg;
}
.status_act {
  color: $green2-bg;
  font-weight: 500;
}
.active_btn {
  border: none !important;
  outline: none !important;
  background: $green2-bg;
  padding: 10px 38px;
  color: $white-bg;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
  white-space: nowrap !important;
}
.ride_history_btn {
  border: none !important;
  outline: none !important;
  background: linear-gradient(to right, #26a3e3, #1683b9);
  padding: 10px 20px;
  color: $white-bg;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
  white-space: nowrap;
}
.active_btn:hover,
.ride_history_btn:hover {
  scale: 95%;
  color: white !important;
}
.orange_btn {
  background: linear-gradient(to right, #fe8048, #e27f54);
}
.red_btn {
  background: $red-bg;
}
.skyblue_gradient {
  background: linear-gradient(to right, #26e0b5, #16a69c);
}
.blue_gradient {
  background: linear-gradient(to right, #26a3e4, #147fb3);
  padding: 10px 34px;
}
.violet_gradient {
  background: linear-gradient(to right, #4d58f9, #5e6bce);
}
.div_wrapper {
  border: 1px solid $secondary-border-clr;
  padding: 10px 15px;
  border-radius: 5px;
  color: $light-black;
}
.div_wrapper.pading {
  border: 1px solid $secondary-border-clr;
  padding: 9px 15px;
  border-radius: 5px;
  color: $light-black;
}
.input_val {
  border: none !important;
  font-size: 0.875rem;
  color: $light-black;
}
.input_val::placeholder {
  border: none !important;
  font-size: 0.875rem !important;
}
tr th,
td p,
td a {
  white-space: nowrap !important;
}
th,
td {
  text-align: center;
}
.convert_btn {
  outline: none;
  border: 1px solid $green-bg;
  background: transparent;
  color: $green-bg;
  font-size: 0.875rem;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  transition: 0.3s;
  cursor: pointer;
}
.convert_btn:hover {
  background: $green-bg;
  color: $white-bg;
}
.convert_btn.reset_btn {
  background-color: $green-bg;
  color: $white-bg;
}
.convert_btn.reset_btn:hover,
.convert_btn.reset_btn:focus,
.convert_btn.reset_btn:focus-visible,
.convert_btn.reset_btn:active {
  background-color: transparent;
  color: $green-bg !important;
  border-color: $green-bg !important;
}
.delte_btn {
  color: $light-red;
  background-color: $light-red-bg;
}
.card_padding {
  padding: 0px 0px !important;
}
.card_earnign {
  border-radius: 8px;
}
.border_none {
  border: none !important;
  border-radius: 4px !important;
  background: none !important;
  color: $white-bg;
  cursor: pointer;
  transition: 0.4s;
}
.border_none:hover {
  transform: scale(98%);
}
.pads {
  padding: 10px 15px;
}
.earnings {
  border-bottom: 1px solid #001d2a47;
}
.earn_amount {
  font-size: 20px;
}
.earn_duration {
  font-size: 11px;
  font-weight: 300 !important;
}
.table_card_body td:last-child {
  padding-right: 50px !important;
}
.table_textStart {
  text-align: start !important;
}
.green {
  background-image: linear-gradient(to right, #2bf2bc, #15a09a);
}
.blue {
  background-image: linear-gradient(to right, #25a2e3, #1582b7);
}
.orange {
  background-image: linear-gradient(to right, #fd8048, #e17f54);
}
.violet {
  background-image: linear-gradient(to right, #4d58f9, #606ccd);
}
.darkBlue {
  background-image: linear-gradient(to right, #27a6e7, #1581b5);
}
.red {
  background: linear-gradient(to right, #f22b2b, #ac3742);
}

.title_fixed {
  background: $white-bg;
  z-index: 1029;
  top: 75px;
  width: 80%;
  position: fixed;
}
.form-card {
  background: $white-bg;
  border: none !important;
}
.label-color {
  color: $light-black !important;
  font-weight: 450;
}
.new_input {
  background-color: transparent !important;
  border: 1px solid $secondary-border-clr !important;
  border-radius: 5px !important;
  color: $light-black !important;
  font-size: 13px !important;
}
.new_input::placeholder {
  color: $light-black !important;
  font-size: 13px !important;
}
.form_map {
  width: 100%;
  min-height: 250px;
  background-image: url("../images//youcab/formmap.png");
  border-radius: 12px;
}
.form_search_input {
  top: 10px;
  background: $white-bg !important;
  width: 100% !important;
  box-shadow: 0px 0px 8px -3px grey;
}
.form-group {
  margin-bottom: 1.5rem !important;
}
.css-yt9ioa-option {
  color: $light-black !important;
  cursor: pointer !important;
}
.css-9gakcf-option {
  background-color: $green-bg !important;
}
.css-1n7v3ny-option:hover {
  background-color: transparent !important;
  color: $light-black !important;
}
.css-1n7v3ny-option:active {
  background-color: $green-bg !important;
  color: white !important;
}
.css-1n7v3ny-option {
  background-color: $green-bg !important;
  color: white !important;
}
.second_div:focus-visible {
  border: none !important;
}
::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}
a {
  text-decoration: none !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: $green-bg;
}

::-webkit-file-upload-button {
  display: none;
}
.new_input.pdf_input {
  padding: 10px;
  font-size: 0.875rem;
}
.change_btn {
  padding-left: 10px;
  padding-right: 10px;
}
.paddingFull_zero {
  padding: 0 !important;
}
.green_clrr {
  color: $light-black;
  padding-left: 25px;
  padding-right: 25px;
}
.green_clrr:hover {
  color: $black-bg;
}
.green_clrr.nav-link.active {
  background: $green2-bg !important;
  color: $white-bg;
  z-index: 100;
  position: relative;
}
.green_clrr.active::after {
  content: "";
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 7px solid $green2-bg;
  top: 64px;
  left: 58px;
}
.h2_style {
  font-weight: 400 !important;
  font-size: 21px;
  margin-bottom: 3px !important;
}
.p_style {
  font-weight: 400;
  margin-bottom: 0px !important ;
}
.tab_cont {
  margin-top: -6px;
  min-height: 300px;
  // background-image: url("../images/youcab//caravailablemap.PNG");
}
.full_img {
  width: 100%;
  height: 100%;
}
.map_input {
  width: 100% !important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $green-bg;
}

input:focus + .slider {
  box-shadow: 0 0 1px $green-bg;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.track_cab {
  background: $light-grey;
  min-width: 280px;
  max-width: 280px;
  border-radius: 8px;
}
.track_cab.trans_border {
  border: none !important;
  border-left: 10px solid $light-grey !important;
}
.track_cab.border {
  border: none !important;
  border-left: 10px solid $green-bg !important;
}

.cab_data {
  padding: 15px;
}
.active_fs {
  font-size: 12px;
}
.pickup_para {
  font-size: 12px;
}
.location {
  font-weight: 450;
}
.cab_pickup {
  border-bottom: 1px solid gray;
  width: 100% !important;
}
.drop_clr {
  color: $green-bg;
}
.div_scroll {
  gap: 15px;
  overflow-x: auto;
  padding-bottom: 15px;
}
.update_btn {
  background: $green3-bg;
  color: $green-bg;
  font-size: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.font_s {
  font-size: 16px;
}
.required_txt {
  font-size: 12px;
  color: red !important;
  font-weight: 400;
}
.modal-content {
  background-color: $white-bg !important;
  color: $light-black !important;
  border: none !important;
}
.modal-header {
  padding: 20px 25px !important;
  border-bottom: 1px solid $light-grey !important;
}
.modal-footer {
  border-top: 1px solid $light-grey !important;
}
.login_input {
  background: $light-grey !important;
  border: none !important;
  color: #bbbbbb !important;
}
.login_input::placeholder {
  color: #bbbbbb !important;
}
.del_btn {
  background-color: #ff5757 !important;
  border: 1px solid #ff5757 !important;
  color: white !important;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
}
.del_btn:hover {
  background-color: white !important;
  color: #ff5757 !important;
  border: 1px solid #ff5757 !important;
}
.can_btn {
  background-color: transparent !important;
  border: 1px solid grey !important;
  color: black !important;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
}
.can_btn:hover {
  background-color: white !important;
  color: #ff5757 !important;
  border: 1px solid #ff5757 !important;
}
.second_div ::placeholder {
  color: red !important;
}
.css-2b097c-container:focus-visible {
  border: none !important;
  outline: none !important;
}
// .css-1n7v3ny-option {
//   background-color: none !important;
// }
.apexcharts-toolbar {
  display: none !important;
}
.driver_graph {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 260px;
}
.pagination_row {
  gap: 20px;
}
.pagination_btn {
  color: $green-bg;
  cursor: pointer;
}
.table td img {
  width: 45px !important;
  height: 45px !important;
}
.react_input_select .css-1wa3eu0-placeholder {
  font-size: 13px !important;
  color: $light-black;
  // font-weight: 450;
}
.react_input_select .css-yk16xz-control {
  border: 1px solid $light-grey !important;
}
.css-1okebmr-indicatorSeparator {
  width: 0px !important;
}
.visible_icon {
  font-size: 20px;
  cursor: pointer;
}
.navbar_new_icons {
  filter: brightness(0.5);
  width: 27px;
}
.dropdown_green_bg {
  background-color: $green-bg !important;
}
.driver_Status .css-yk16xz-control {
  border-color: #dedede !important;
}
.css-1uccc91-singleValue {
  font-size: 13px !important;
}
.account_btn {
  padding: 10px 20px !important;
}

.custom_checkbox {
  width: 20px !important;
  height: 20px !important;
  border-color: $green-bg !important;
  accent-color: $green-bg !important;
  color: white !important;
  background-color: white !important;
}
input[type="checkbox"]:checked {
  background-color: #a77e2d !important;
  color: #ffffff !important;
}
input[type="checkbox"]:after {
  box-shadow: none !important;
}
.select_border_clr .css-yk16xz-control {
  // border: 1px solid grey !important;
  border-color: $secondary-border-clr !important;
}
.search_field {
  padding: 5px 10px !important;
  border: 1px solid $secondary-border-clr;
  border-radius: 4px;
}
.view-bg {
  background: #f1f6f3;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.p-20 {
  padding-right: 20px;
  padding-left: 20px;
}
.filleye_icon {
  color: $green-bg;
  font-size: 15px;
}
.search_field:focus {
  border: 1px solid $secondary-border-clr !important;
}
.table_p {
  font-size: 0.875rem;
  line-height: 1;
  padding: 0.9375rem;
  font-weight: 500;
  color: #6c7293;
  background: $light-grey;
}
.table_bgNone tr {
  background: none !important;
}
.table_bgNone tr th {
  color: $light-black !important;
  font-weight: 400 !important;
}
.table_bgNone tr td {
  color: $light-black !important;
  font-weight: 500 !important;
}

// .gif_bg {
//   background-image: url("../images/youcab/bg.png");
// }
// .css-g1d714-ValueContainer {
//   padding-top: 5px !important;
//   padding-bottom: 5px !important;
// }

.dotted_line {
  height: 51px;
  width: 2px;
  background-color: $light-black;
}
.notification_btn {
  background: $green-bg;
  color: white !important;
  width: fit-content;
  border-radius: 5px;
  transition: 0.4s;
  cursor: pointer;
}
.notification_btn:hover {
  transform: scale(98%);
}
.login_hover:hover {
  border: 1px solid $green-bg;
  color: $green-bg !important;
}
.login_hover:active,
.login_hover:focus {
  background: $green-bg !important;
  border-color: $green-bg !important;
}
.pagination_overflow {
  gap: 5px;
  overflow-x: auto;
  min-width: 220px;
  max-width: 220px;
  // overflow: hidden;
}
.dis_inline_parent {
  display: inline-block;
  max-width: 100%;
  // overflow-x: auto;
  white-space: nowrap;
  min-height: 50px;
}
.dis_inline {
  display: inline;
}
.form-control {
  color: $light-grey-text !important;
}
.form-control::placeholder {
  color: $light-grey-text !important;
}
.search_icon {
  cursor: pointer;
  transition: 0.4s;
}
.search_icon:hover {
  transform: scale(90%);
}
.map_pointer_wrapper {
  background: #f4f4f4;
  box-shadow: 0px 0px 8px -3px rgb(140, 232, 197);
  min-width: 180px;
  max-width: 180px;
}
.map_pointer_wrapper p {
  font-size: 10px;
}
.map_hover_top_sec {
  padding: 10px 15px;
}
.map_pointer_wrapper .top_p {
  font-weight: 450;
}
.map_pointer_wrapper .top_p span {
  font-weight: 100 !important;
}
.map_pointer_wrapper .cancel_icon {
  font-size: 10px;
  color: red;
}
.map_pointer_wrapper .middle_sec {
  padding: 10px 15px;
  background: #e4e4e4;
}
.map_pointer_wrapper img {
  width: 10px;
}
.map_pointer_wrapper .bot_sec {
  padding: 10px 15px;
}
.map_pointer {
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.new_hover:hover {
  border: 1px solid $green-bg !important;
  color: $green-bg !important;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.show_select .css-g1d714-ValueContainer {
  padding: 2px 21px !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: $green-bg !important;
  color: #fff;
}

.react-datepicker__input-container input {
  border: none !important;
  width: 100% !important;
}
.track_cabs_bg {
  background-color: white !important;
  box-shadow: 0px 0px 8px -3px rgb(140, 232, 197);
  padding: 30px 30px;
}
.track_cabs_map_wrapper {
  top: 30%;
  left: 40%;
}
.track_cabs_map_wrapper img {
  width: 20px;
}
.single_driver_dtls {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.single_driver_dtls p {
  margin: 0;
  white-space: nowrap;
}
.driver_dtls {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  max-width: 700px;
  padding: 15px;
  border-radius: 10px;
  background-color: $white-bg;
  border: 1px solid $green-bg;
}
.driver_dtls_whole {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translate(-50%, 50%);
}
.driver_dtls_whole p {
  font-size: 10px;
}
.img_round {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bor_none {
  border: none !important;
}
.date_minht {
  min-height: 43px !important;
  display: flex !important;
  align-items: center !important;
}
.row_pad_top {
  padding-top: 85px !important;
}
.slt_min-width {
  min-width: 25% !important;
}
.login_gif {
  position: absolute;
  width: 100%;
}
.gif_bg {
  position: absolute;
  width: 100%;
}

// Media query

@media (min-width: 576px) {
  .select_div {
    width: 25% !important;
  }
  .select_div.min-wid {
    width: 50% !important;
  }
  .rst_btn.surcharge_btn {
    background-color: $green2-bg;
    white-space: nowrap;
    // width: fit-content !important;
  }
  .row_pad_top {
    padding-top: 50px !important;
  }
  .select_div {
    max-width: 130px !important;
  }
}

@media (min-width: 992px) {
  .page-body-wrapper {
    margin-left: #{$sidebar-width-lg};
  }
  // .main-panel {
  //   position: fixed;
  //   width: calc(100vw - 260px);
  // }
  // .sidebar-offcanvas {
  //   overflow: auto;
  // }
  .login_screen_center {
    margin-left: -260px !important;
  }
  .title_fixed {
    width: calc(100% - 320px) !important;
  }
  .title_fixed div:first-child {
    background-color: #fff !important;
  }
}
@media (min-width: 1441px) {
  .page-body-wrapper {
    margin-left: #{$sidebar-width-lg};
  }
  .col_width {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row_width {
    width: 85% !important;
  }

  .select_div.min-wid {
    width: 25% !important;
  }
  .max_wid_25 {
    max-width: 25% !important;
  }
  .max_wid_15 {
    max-width: 15% !important;
  }

  .slt_min-width.select_div {
    width: 150px !important;
    min-width: 150px !important;
    max-width: 150px !important;
  }
  .srch_min_wid {
    width: 350px !important;
    min-width: 350px !important;
    max-width: 350px !important;
  }
}

@media (max-width: 991px) {
  .sidebar .nav {
    margin-top: 0px !important;
    max-height: calc(100vh - 100px) !important;
  }
  .content-wrapper {
    padding: 1.875rem 15px;
  }

  .paddingx_zero {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .navbar .navbar-brand-wrapper {
    width: 95px !important;
  }
  .title_fixed {
    width: calc(100% - 0px);
    left: 0px;
    padding: 0px 20px;
  }
}

.active_btn.delte_btn:hover {
  color: #ff5757 !important;
}

@media only screen and (max-width: 767px) {
  .driver_dtls_whole {
    position: relative;
    bottom: unset !important;
    left: unset !important;
    transform: unset !important;
    margin-top: 20px !important;
    // bottom: 70%;
  }
  .driver_dtls {
    grid-template-columns: 50% 50%;
  }
  .single_driver_dtls {
    margin-bottom: 20px !important;
    padding: 0px 10px;
  }
  .single_driver_dtls:nth-child(3),
  .single_driver_dtls:last-child {
    margin-bottom: 0px !important;
  }
}
.img_round {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid $green-bg;
}
.active_btn.update_btn {
  width: fit-content !important;
}
.active_btn.update_btn:hover {
  color: $green-bg !important;
}
.error-msg {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}

.dis_inline_parent .convert_btn {
  margin-right: 5px;
}
.dis_inline.convert_btn {
  display: inline-block;
}

// .pagination_overflow {
//   min-width: calc(100vw - 40%);
// }
// .pagination_scrl_none ::-webkit-scrollbar {
//   height: 0px !important;
// }

// @media only screen and (min-width: 992px) {
//   .pagination_overflow {
//     min-width: calc(100vw - 260px - 40%);
//   }
// }

// sakthi
.err_msg_txt {
  color: red;
}
.err_msg_txt::first-letter {
  text-transform: uppercase;
}

.modal.show {
  display: block !important;
}
.modal-backdrop {
  z-index: 10001;
}
.modal {
  z-index: 100001;
}

table thead tr {
  display: table-row !important;
}

// start of hariharan css 1-11-23

@media (min-width: 576px) {
  .slt_div {
    min-width: 140px;
    max-width: 140px;
  }
}
@media (min-width: 768px) {
  .slt_div {
    min-width: 160px;
    max-width: 160px;
  }
}
@media (max-width: 575px) {
  .ipt_wid_100 {
    width: 100% !important;
  }
}

// end of hariharan css 1-11-23

.input_with_eye {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f2f2f2 !important;
  border: none !important;
  border-radius: 5px !important;
}
.input_with_eye .fa {
  padding-right: 10px;
  cursor: pointer;
}
.input_with_eye .fa:hover {
  color: #76a488 !important;
}
a.text-muted:hover {
  color: #76a488 !important;
}
.custom_file_new input {
  width: 100%;
  height: 100%;
  z-index: 5;
  position: relative;
  border: 1px solid #dedede !important;
}
.custom_file_new .custom-file-label {
  background-color: transparent !important;
  background: transparent !important;
  border: none !important;

  border-radius: 5px !important;
  // z-index: 5;
  // position: absolute;
}
.custom_file_new .custom-file-label::after {
  background-color: #76a488 !important;
  border-color: #76a488 !important;
  background: #76a488 !important;
}
.new_input.new_input_add {
  background-color: #76a488 !important;
  border-color: #76a488 !important;
  background: #76a488 !important;
  color: #fff !important;
  min-height: 35px !important;
  line-height: 34px;
  padding: 2px 10px;
  cursor: pointer;
  max-width: max-content;
  text-align: center;
  max-width: 110px !important;
}
a.text-dark:hover {
  color: #76a488 !important;
  // font-weight: 700 !important;
}
.button_right_max_width {
  max-width: max-content;
  margin-left: auto;
}

@media only screen and (max-width: 575px) {
  .button_right_max_width {
    margin-left: auto;
    margin-right: auto;
  }
}
.mx_width_cont_bt {
  max-width: max-content;
}

@media only screen and (min-width: 768px) {
  .mx_width_cont_bt {
    margin-left: auto !important;
  }
}
.card_driver_bog p {
  color: #000 !important;
}
.table_rider_width_set th:nth-child(2),
.table_rider_width_set th:nth-child(5),
.table_rider_width_set td:nth-child(2),
.table_rider_width_set td:nth-child(5) {
  min-width: 200px !important;
}

.triproute_table_width th:nth-child(4),
.triproute_table_width td:nth-child(4) {
  min-width: 200px !important;
}

.triproute_table_width th:nth-child(3),
.triproute_table_width td:nth-child(3) {
  min-width: 200px !important;
}

.triproute_table_width th:nth-child(5),
.triproute_table_width td:nth-child(5) {
  min-width: 200px !important;
}

.triproute_table_width th:nth-child(6),
.triproute_table_width td:nth-child(6) {
  min-width: 200px !important;
}
.show_select {
  min-width: 105px;
}
.hoverable_a {
  color: #000;
  cursor: pointer;
}
.hoverable_a:hover {
  color: #5c856c;
}
.no_link_a {
  color: #fff !important;
  font-weight: 600;
}
.custom_file_browse .custom-file-label {
  background-color: #ffffff !important;
  border: 1px solid #dedede !important;
  border-radius: 5px !important;
  color: #403c3e !important;
}
.custom-file.custom_file_browse .custom-file-label:after {
  background-color: #76a488 !important;
  border-color: #76a488 !important;
  color: #000 !important;
}
.flex_img_div_upload {
  display: flex;
  align-items: center;
}

.uploaded_img {
  max-width: 100px;
  max-height: 100px;
  min-width: 100px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 1px solid #dedede !important;
  border-radius: 5px !important;
  margin-right: 10px;
  margin-bottom: 1.5rem;
}
.form-group.mb-0 {
  margin-bottom: 0px !important;
}
.flex_notification_div {
  border: 1px solid #dedede !important;
  border-radius: 5px !important;
  display: flex;
  align-items: center;
  padding: 10px;
  color: #000;
  font-size: 14px;
}
.flex_notification_div img {
  max-width: 50px;
  max-height: 50px;
  min-width: 50px;
  min-height: 50px;
  border: 1px solid #dedede !important;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
// .custom_file_browse .custom-file-label
// {
//   z-index: 0 !important;
// }
.uploaded_img img {
  object-fit: contain;
  max-width: 100%;
}

.notification_table_div th:first-child,
.notification_table_div td:first-child {
  max-width: 50px !important;
  padding-left: 20px !important;
}

.notification_table_div .table_card_body .table tr th:nth-child(2),
.notification_table_div .table_card_body .table tr td:nth-child(2) {
  min-width: 200px !important;
}
.notification_table_div .table_card_body .table tr th:nth-child(3),
.notification_table_div .table_card_body .table tr td:nth-child(3) {
  min-width: 300px !important;
}
.notification_table_div .table_card_body .table tr th:nth-child(4),
.notification_table_div .table_card_body .table tr td:nth-child(4) {
  min-width: 200px !important;
}

.notification_table_div .table_card_body .table tr th:last-child,
.notification_table_div .table_card_body .table tr td:last-child {
  max-width: 200px !important;
}

.discountadd_table_div th:first-child,
.discountadd_table_div td:first-child {
  max-width: 50px !important;
  padding-left: 20px !important;
}

.discountadd_table_div .table_card_body .table tr th:nth-child(2),
.discountadd_table_div .table_card_body .table tr td:nth-child(2) {
  min-width: 200px !important;
}

.discountadd_table_div .table_card_body .table tr th:nth-child(3),
.discountadd_table_div .table_card_body .table tr td:nth-child(3) {
  min-width: 100px !important;
}

.discountadd_table_div .table_card_body .table tr th:nth-child(4),
.discountadd_table_div .table_card_body .table tr td:nth-child(4) {
  min-width: 100px !important;
}

.discountadd_table_div .table_card_body .table tr th:nth-child(6),
.discountadd_table_div .table_card_body .table tr td:nth-child(6) {
  min-width: 200px !important;
}

@media only screen and (max-width: 575px) {
  .uploaded_img {
    max-width: 65px;
    max-height: 65px;
    min-width: 65px;
    min-height: 65px;
  }
}
.input_radio_custom {
  width: 20px;
  height: 20px;
}

.input_radio_custom:checked,
.form-check-input:checked {
  background-color: red !important;
}

.upload_doc_div_flex {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.img_div_upl {
  max-width: 100px;
  min-width: 100px;
  max-height: 100px;
  min-height: 100px;
  border: 1px solid #dedede;
  border-radius: 10px;
  overflow: hidden;
}
.img_div_upl img {
  object-fit: contain;
  max-width: 100px;
  min-width: 100px;
  max-height: 100px;
  min-height: 100px;
  overflow: hidden;
}
// .triproute_table_width th:first-child,
// .triproute_table_width td:first-child
// {
// max-width: 100px !important;
// }

/** start of hariharan css **/

.custom_react_select__control--is-focused,
.custom_react_select__control--menu-is-open {
  box-shadow: none !important;
  border-color: #76a488 !important;
}
// .custom_react_select__control {
//   border: none !important;
// }

/** end of hariharan css **/
